/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'outline',
    custom: {
      ic_arrow_drop_down: {
        viewbox: '0 0 24 24',
        content: '<path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z"/>'
      }
    }
  }
}
